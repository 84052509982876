export class Associado {
  constructor({
    id,
    codigo,
    nome,
    cpf,
    rg,
    pessoaFis,
    cnpj,
    inscricao_estadual,
    razao_social,
    nome_fantasia,
    email,
    telefones,
    pais,
    regional,
    estado,
    cidade,
    bairro,
    logradouro,
    numero,
    cep,
    complemento,
    data_ingresso,
    role,
    status,
    eventos,
  } = {
      id: '',
      codigo: '',
      nome: '',
      cpf: '',
      rg: '',
      pessoaFis: '',
      cnpj: '',
      inscricao_estadual: '',
      razao_social: '',
      nome_fantasia: '',
      email: '',
      telefones: [],
      pais: '',
      regional: '',
      estado: '',
      cidade: '',
      bairro: '',
      logradouro: '',
      numero: '',
      cep: '',
      complemento: '',
      data_ingresso: '',
      role: '',
      status: 0,
      eventos: [],
    }) {
    this.id = id
    this.codigo = codigo
    this.nome = nome
    this.cpf = cpf
    this.rg = rg
    this.pessoaFis = pessoaFis
    this.cnpj = cnpj
    this.inscricao_estadual = inscricao_estadual
    this.razao_social = razao_social
    this.nome_fantasia = nome_fantasia
    this.email = email
    this.telefones = telefones
    this.pais = pais
    this.estado = estado
    this.cidade = cidade
    this.bairro = bairro
    this.logradouro = logradouro
    this.numero = numero
    this.cep = cep
    this.complemento = complemento
    this.data_ingresso = data_ingresso
    this.role = role
    this.regional = regional
    this.status = status
    this.eventos = eventos
  }
}

export function createAssociado (data) {
  return Object.freeze(new Associado(data))
}
