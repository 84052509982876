<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <crud-cpf-dialog
      :dialog="dialog"
      :titulo="'Associado'"
      :profile="'associado'"
      :store="'associados'"
      @close-dialog="setFields"
    />
    <div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
      >
        <crud-cadastro
          :loading="loading"
        >
          <v-tabs
            v-model="tab"
            centered
            center-active
            fixed-tabs
            icons-and-text
            style="padding-top: 0px;  margin-top: 0px"
          >
            <v-tabs-slider />
            <v-tab>
              Dados do Associado
              <v-icon>
                mdi-account-supervisor
              </v-icon>
            </v-tab>
            <v-tab>
              Parâmetros de Cálculo
              <v-icon>
                mdi-calculator
              </v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <div class="content">
                <crud-card title="Dados Principais">
                  <v-row
                    v-if="picked === 'fisica'"
                  >
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="codigo"
                        dense
                        type="number"
                        autocomplete="off"
                        label="Código*"
                        :disabled="isEditing"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        rounded
                        hide-spin-buttons
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="nome"
                        dense
                        outlined
                        rounded
                        autocomplete="off"
                        label="Nome*"
                        validate-on-blur
                        :rules="[rules.required]"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="email"
                        outlined
                        rounded
                        dense
                        autocomplete="new-email"
                        :rules="[rules.required, rules.email]"
                        :disabled="$disabledFields"
                        label="Email*"
                        validate-on-blur
                        @input="email = email.toLowerCase()"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="cpf"
                        v-mask="maskCPF"
                        outlined
                        rounded
                        dense
                        autocomplete="off"
                        label="CPF*"
                        disabled="disabled"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="3"
                    >
                      <v-text-field
                        v-model="rg"
                        :rules="[rules.required]"
                        outlined
                        rounded
                        dense
                        autocomplete="off"
                        label=" RG*"
                        validate-on-blur
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <crud-contato
                        :telefones="telefones"
                        :cols="6"
                        :input-outlined="true"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-else
                  >
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <v-text-field
                        v-model="codigo"
                        dense
                        type="number"
                        autocomplete="off"
                        label="Código*"
                        :rules="[rules.required]"
                        validate-on-blur
                        outlined
                        rounded
                        hide-spin-buttons
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="razao_social"
                        dense
                        autocomplete="nope"
                        :rules="[rules.required]"
                        label="Razão Social*"
                        validate-on-blur
                        outlined
                        rounded
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="5"
                    >
                      <v-text-field
                        v-model="nome_fantasia"
                        dense
                        autocomplete="nope"
                        class="no-auto-complete"
                        label="Nome Fantasia"
                        validate-on-blur
                        outlined
                        rounded
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="cnpj"
                        v-mask="maskCnpj"
                        dense
                        autocomplete="nope"
                        :rules="[rules.required]"
                        disabled="disabled"
                        label="CNPJ*"
                        validate-on-blur
                        outlined
                        rounded
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="inscricao_estadual"
                        dense
                        autocomplete="nope"
                        label="Inscrição Estadual"
                        validate-on-blur
                        outlined
                        rounded
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <v-text-field
                        v-model="email"
                        dense
                        autocomplete="nope"
                        :rules="[rules.required, rules.email]"
                        label="Email*"
                        :disabled="$disabledFields"
                        validate-on-blur
                        outlined
                        rounded
                        @input="email = email.toLowerCase()"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <crud-contato
                        class="ml-0 mr-0"
                        :telefones="telefones"
                        :cols="6"
                        :input-outlined="true"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="md">
                    <v-col
                      cols="6"
                      md="6"
                    >
                      <v-autocomplete
                        v-model="role"
                        :loading="!roles"
                        :items="roles"
                        dense
                        outlined
                        rounded
                        color="blue-grey lighten-2"
                        label="Papel do usuário"
                        item-text="description"
                        item-value="id"
                        :rules="[rules.required]"
                        autocomplete="off"
                      />
                    </v-col>
                    <v-col
                      cols="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="data_ingresso"
                        v-mask="maskData"
                        dense
                        outlined
                        rounded
                        autocomplete="off"
                        :rules="[
                          rules.required,
                          rules.validDate,
                          rules.isNotFuture
                        ]"
                        label="Data de Ingresso *"
                        validate-on-blur
                      />
                    </v-col>
                  </v-row>
                </crud-card>
                <crud-localizacao
                  title="Localização"
                  class="mt-3"
                />
              </div>
            </v-tab-item>
            <v-tab-item>
              <div class="content">
                <crud-list
                  v-model="eventos"
                  :headers="headersEventos"
                  :actions="actionsEventos"
                  :sort-desc="[false]"
                  :sort-by="['evento.codigo']"
                  :show-search="false"
                  item-height="596"
                  :slots="[
                    'item.evento.codigo',
                    'item.evento.descricao',
                    'item.evento.tipo_evento',
                    'item.evento.tipo_calculo',
                    'item.evento.tipo_variacao',
                    'item.valor_evento'
                  ]"
                  class="pt-2 elevation-0"
                >
                  <template v-slot:[`item.evento.codigo`]="{ item }">
                    <span>
                      {{ item.evento.codigo }}
                    </span>
                  </template>
                  <template v-slot:[`item.evento.descricao`]="{ item }">
                    <span>
                      {{ item.evento.descricao }}
                    </span>
                  </template>
                  <template v-slot:[`item.evento.tipo_evento`]="{ item }">
                    <span>{{ item.evento.codigo.substring(4, 7) !== '000' ? item.evento.tipo_evento === 0 ? 'Crédito' : item.evento.tipo_evento === 1 ? 'Débito' : '' : '' }}</span>
                  </template>
                  <template v-slot:[`item.evento.tipo_calculo`]="{ item }">
                    <span>{{ item.evento.codigo.substring(4, 7) !== '000' ? item.evento.tipo_calculo === 0 ? 'Percentual' : item.evento.tipo_calculo === 1 ? 'Valor' : 'Transferência Percentual' : '' }}</span>
                  </template>
                  <template v-slot:[`item.evento.tipo_variacao`]="{ item }">
                    <span v-if="item.evento.tipo_calculo !== 2">{{ item.evento.codigo.substring(4, 7) !== '000' ? item.evento.tipo_variacao === 0 ? 'Fixo no evento' : item.evento.tipo_variacao === 1 ? 'Variável por Pessoa' : '' : '' }}</span>
                    <span v-if="item.evento.tipo_calculo === 2">{{ item.evento.codigo.substring(4, 7) !== '000' ? item.evento.tipo_variacao_transf === 0 ? 'Fixo no evento' : item.evento.tipo_variacao_transf === 1 ? 'Variável por Pessoa' : '' : '' }}</span>
                  </template>
                  <template v-slot:[`item.valor_evento`]="{ item }">
                    <span>
                      {{ `${currencyFormatter(parseFloat(item.valor_evento), 2)}` }}
                    </span>
                  </template>
                  <template slot="datas">
                    <v-col
                      cols="8"
                    >
                      <v-autocomplete
                        v-model="escolheEventos"
                        :items="eventosDisponiveis()"
                        item-text="descricao"
                        item-value="id"
                        return-object
                        label="Selecione o Evento*"
                        no-data-text="Não há Eventos disponíveis"
                        clearable
                        cache-items
                        autocomplete="off"
                        style="width:600px"
                        validate-on-blur
                        outlined
                        rounded
                        dense
                        hide-details
                      >
                        <template
                          slot="item"
                          slot-scope="data"
                        >
                          <v-list-item-content
                            style="max-height: 100px"
                            class="overflow-y-auto"
                          >
                            <v-list-item-title>{{ data.item.codigo + '  -  ' + data.item.descricao }}</v-list-item-title>
                            <span
                              v-if="data.item.disabled"
                              color="red"
                            ><b><i>Este Evento já está lançado</i></b></span>
                          </v-list-item-content>
                        </template>
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="3"
                    >
                      <base-vuetify-money
                        v-if="( (escolheEventos?.tipo_calculo === 0 && escolheEventos?.tipo_variacao === 1 ) || ( escolheEventos?.tipo_calculo === 2 && escolheEventos?.tipo_variacao_transf === 1 ))"
                        v-model="valor_evento"
                        :options="percent"
                        maxlength="6"
                        label="Percentual*"
                        validate-on-blur
                        :rules="[rules.required]"
                        dense
                        outlined
                        rounded
                        hide-details
                        class="centered-input"
                      />
                      <base-vuetify-money
                        v-if="escolheEventos?.tipo_calculo === 1 && escolheEventos?.tipo_variacao === 1"
                        v-model="valor_evento"
                        :options="money"
                        maxlength="14"
                        label="Valor*"
                        validate-on-blur
                        :rules="[rules.required]"
                        dense
                        outlined
                        rounded
                        hide-details
                        class="centered-input"
                      />
                    </v-col>
                  </template>
                  <template v-slot:btnCadastro>
                    <v-col
                      cols="1"
                      class="text-center"
                    >
                      <v-btn
                        color="success"
                        class="text-none text-white"
                        block
                        rounded
                        @click="adicionaEvento()"
                      >
                        <v-icon class="pr-1">
                          mdi-download
                        </v-icon>
                        Adiciona evento
                      </v-btn>
                    </v-col>
                  </template>
                </crud-list>
              </div>
            </v-tab-item>
          </v-tabs-items>
          <v-row
            class="pa-3"
            justify="center"
            justify-sm="end"
          >
            <v-btn
              class="mr-3"
              color="error"
              rounded
              @click="
                $router.push({ path: `/${$user.get().role}/associados` })
              "
            >
              <v-icon
                dark
                left
              >
                mdi-minus-circle
              </v-icon>
              Cancelar
            </v-btn>
            <v-btn
              color="success"
              :disabled="desabilitaBotao"
              rounded
              @click="cadastrarAssociado"
            >
              <v-icon
                dark
                left
              >
                mdi-checkbox-marked-circle
              </v-icon>
              Salvar
            </v-btn>
          </v-row>
        </crud-cadastro>
      </v-form>
    </div>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import AssociadoFormStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/associado'
  import { mapState, mapActions } from 'vuex'
  import { currencyFormatter } from '../../utils/formatter'
  export default {
    data () {
      return {
        tab: 0,
        valid: false,
        loadedData: false,
        maskData: '##/##/####',
        maskCPF: '###.###.###-##',
        maskCnpj: '##.###.###/####-##',
        currencyFormatter,
        escolheEventos: null,
        valor_evento: 0,
        percent: {
          locale: 'pt-BR',
          precision: 2,
          prefix: '%',
        },
        money: {
          locale: 'pt-BR',
          precision: 2,
          prefix: 'R$',
        },
        telefones: [
          { tipo: 'Comercial', format: 'com', title: 'Telefone 1', required: true },
          { tipo: 'Comercial', format: 'com', title: 'Telefone 2' },
        ],
        rules: rules,
        snackbar: { message: '', show: false },
        actionsEventos: [
          {
            title: 'Excluir',
            color: 'red darken-3',
            click: item => this.removerEvento(item),
            icon: 'mdi-trash-can-outline',
          },
        ],
        headersEventos: [
          { text: 'Ações', value: 'actions', sortable: false, groupable: false, floatingfilter: false, width: '40px' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Código', value: 'evento.codigo', width: '20' },
          { align: 'left', sortable: true, groupable: false, floatingfilter: true, text: 'Descrição', value: 'evento.descricao', width: 'auto' },
          { align: 'left', sortable: false, groupable: false, text: 'Tipo de evento', value: 'evento.tipo_evento', width: 'auto', floatingfilter: false, report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Tipo de variação', value: 'evento.tipo_variacao', width: 'auto', floatingfilter: false, report: true },
          { align: 'left', sortable: false, groupable: false, text: 'Tipo de cálculo', value: 'evento.tipo_calculo', width: 'auto', floatingfilter: false, report: true },
          { align: 'end', sortable: false, groupable: false, text: 'Valor do evento', value: 'valor_evento', width: 'auto', floatingfilter: false, report: true },
        ],
      }
    },
    computed: {
      ...mapState('form/associado', ['loading', 'roles', 'isEditing']),
      ...mapFields('form', ['data.codigo', 'data.data_ingresso', 'data.pessoaFis', 'data.nome_fantasia', 'data.role', 'data.razao_social', 'data.cnpj', 'data.inscricao_estadual', 'data.email', 'data.cpf', 'data.nome', 'data.rg', 'data.eventos']),
      ...mapFields('form/associado', ['picked', 'dialog', 'loadedEventos']),
      $disabledFields () {
        if (this.loaded || this.isEditing) {
          return 'disabled'
        }
        return null
      },
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.associado) { this.$store.registerModule(['form', 'associado'], AssociadoFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/associado', [BOOTSTRAP_PROFILE, SUBMIT]),
      async setFields (data) {
        this.dialog = false

        if (data.value) {
          const { tipo, value } = data
          this.picked = tipo === 'cpf' ? 'fisica' : 'juridica'
          this.$store.commit('form/UPDATE_FORM_FIELD', { [tipo]: value })
          return
        }

        this.loadedData = true

        const id = data.id
        this.$router.push({
          path: `/${this.$user.get().role}/associados/cadastrar/`,
          query: { id },
        })
        this.BOOTSTRAP_PROFILE({ id })
      },
      eventosDisponiveis () {
        const eventos_disponiveis = this.loadedEventos.filter(x1 => {
          let retorno = true
          this.$store.state.form.data.eventos.forEach(x2 => {
            if (x1.id === x2.evento.id) {
              retorno = false
            }
          })
          return retorno
        })
        return eventos_disponiveis
      },
      adicionaEvento () {
        if (this.escolheEventos === null || this.escolheEventos === '') {
          this.snackbar = {
            show: true,
            message: 'É necessário informar um Evento! Por favor, informe um Evento para continuar!',
            icon: 'success',
          }
          return null
        }

        if (this.valor_evento === '') {
          this.valor_evento = 0
        } else {
          this.valor_evento = parseFloat(this.valor_evento)
        }

        if (this.escolheEventos.tipo_variacao === 1 && this.valor_evento <= 0) {
          this.snackbar = {
            show: true,
            message: 'Não é permitido informar este Evento sem um valor válido. Por favor, preencha-os campos corretamente!',
          }
          return null
        }

        this.$store.state.form.data.eventos.push({ evento: this.escolheEventos, valor_evento: this.valor_evento })

        this.escolheEventos = {}
        this.valor_evento = 0.00
      },
      removerEvento (item) {
        const index = this.$store.state.form.data.eventos.indexOf(item)
        this.$store.state.form.data.eventos.splice(index, 1)
      },
      cadastrarAssociado () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>
